import { navigate } from "@reach/router";
import gql from "graphql-tag";
import * as React from "react";
import { Query } from "react-apollo";
import ReactSelect from "react-select";

import { makeRecordingPath } from "../utils";
import { SearchQuery, SearchQueryVariables } from "./__generated__/SearchQuery";
import Spinner from "./common/spinner";
import Hit from "./hit";

const query = gql`
  query SearchQuery($query: String!) {
    search(query: $query, limit: 300) {
      title
      text
      firstWordIndex
    }
  }
`;

class SearchQueryComponent extends Query<SearchQuery, SearchQueryVariables> {}

interface State {
  searchQuery: string;
  delayActive: boolean;
}

export default class Search extends React.Component<any, State> {
  private timer: NodeJS.Timer | undefined;
  constructor(props: any) {
    super(props);
    this.state = {
      searchQuery: "",
      delayActive: true
    };
  }

  private handleUpdateSearchQuery = (newText: string) => {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      this.setState({ delayActive: false });
    }, 200);
    this.setState({ searchQuery: newText, delayActive: true });
  };

  private handleItemSelected = (item: any) => {
    if (!item) return;
    const { title, firstWordIndex } = item.hit;
    navigate(makeRecordingPath(title) + `?wordIndex=${firstWordIndex}`);
  };

  public render() {
    const { searchQuery, delayActive } = this.state;
    return (
      <SearchQueryComponent
        query={query}
        skip={!searchQuery || delayActive}
        variables={{ query: searchQuery }}
      >
        {({ loading, data }) => {
          const options =
            data && data.search && !loading
              ? data.search.map(hit => ({
                  hit,
                  label: <Hit text={hit.text} title={hit.title} />
                }))
              : undefined;
          return (
            <ReactSelect
              components={{
                DropdownIndicator: () => null,
                NoOptionsMessage: () => null,
                LoadingIndicator: () => (
                  <Spinner style={{ padding: "5px 8px 0 0" }} />
                )
              }}
              onInputChange={this.handleUpdateSearchQuery}
              options={options}
              isLoading={loading}
              onChange={this.handleItemSelected}
              filterOption={() => true}
              placeholder="Search everything..."
              styles={{
                noOptionsMessage: base => ({ ...base, display: "none" }),
                indicatorSeparator: base => ({ ...base, display: "none" })
              }}
            />
          );
        }}
      </SearchQueryComponent>
    );
  }
}
