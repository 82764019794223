import { Link } from "gatsby";
import * as React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";

import { getImage } from "../../helpers";

const Container = styled.div`
  img {
    max-width: 275px;
    max-height: 275px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    transition: box-shadow 0.35s;
  }
  img:hover {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.8);
    transition: box-shadow 0.35s;
  }
  max-width: 275px;
`;

const titleStyles = css`
  text-decoration: none;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  div {
    margin-top: 4px;
  }
`;

interface TileProps {
  date: { formatted: string };
  title: string;
  image: string | null;
  link: string;
}

const Tile: React.SFC<TileProps> = ({ title, image, link }) => (
  <Container>
    <Link to={link}>
      <img src={getImage(image)} alt="Transcription Image" />
    </Link>
    <Link css={titleStyles} to={link}>
      <div>{title}</div>
    </Link>
  </Container>
);

export default Tile;
