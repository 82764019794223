import { Link } from "gatsby";
import * as React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";

interface TileSectionProps {
  tiles: JSX.Element[];
  title: string;
  seeMoreLink?: string;
}

const Container = styled.div`
  h1 {
    margin: 24px 0;
  }
`;

const Header = styled.div`
  position: relative;
`;

const SeeMoreLinkContainer = styled.div`
  a {
    text-decoration: none;
  }
  text-transform: uppercase;
  opacity: 0.6;
  font-size: 12.5px;
  line-height: 16.5px;
  letter-spacing: 0.17em;
  position: absolute;
  top: 11px;
  right: 0;
`;

const breakpoints = [745, 520, 400];

const TilesContainer = styled.div`
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 -8px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);

  > * {
    margin: 8px;
    grid-column: span 3;
    img {
      width: 100%;
    }
  }

  @media only screen and (max-width: 745px) {
    > * {
      grid-column: span 4;
    }
  }

  @media only screen and (max-width: 520px) {
    > * {
      grid-column: span 6;
    }
  }

  @media only screen and (max-width: 400px) {
    > * {
      grid-column: span 12;
    }
  }
`;

const previewStyles = css`
  @media only screen and (max-width: 745px) {
    > :nth-last-of-type(-n + 2) {
      display: none;
    }
  }

  @media only screen and (max-width: 520px) {
    > :nth-last-of-type(-n + 4) {
      display: none;
    }
  }

  @media only screen and (max-width: 400px) {
    > :nth-last-of-type(-n + 6) {
      display: none;
    }
  }
`;

const TileSection: React.SFC<TileSectionProps> = ({
  seeMoreLink,
  title,
  tiles
}) => {
  const seeMore = seeMoreLink ? (
    <SeeMoreLinkContainer>
      <Link to={seeMoreLink}>See More</Link>
    </SeeMoreLinkContainer>
  ) : null;

  return (
    <Container>
      <Header>
        <h1>{title}</h1>
        {seeMore}
      </Header>
      <TilesContainer css={previewStyles}>{tiles}</TilesContainer>
    </Container>
  );
};

export default TileSection;
