import * as React from "react";

import Tile from "./components/common/tile";
import { Recording } from "./types";
import { makeRecordingPath } from "./utils";

export function makeTile(recording: Recording, i = 0) {
  return (
    <Tile
      key={`recording${i}`}
      {...recording}
      link={makeRecordingPath(recording.title)}
    />
  );
}
