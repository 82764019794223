import * as React from "react";
import styled from "@emotion/styled";

const Title = styled.div`
  font-size: 10px;
  color: grey;
`;

const Text = styled.div`
  font-size: 14px;
  color: black;
  em {
    background-color: #ffff00;
  }
`;

interface Props {
  text: string;
  title: string;
}

export default ({ text, title }: Props) => (
  <div>
    <Text
      className="papio-hit-text"
      dangerouslySetInnerHTML={{
        __html: text
          .split(" ")
          .slice(0, 13)
          .join(" ")
      }}
    />
    <Title>{title}</Title>
  </div>
);
